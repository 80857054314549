<template>
  <div class="w-full grid grid-cols-12 font-rubik">
    <app-header />
    <div
      class="col-span-12 sm:col-start-2 sm:col-span-10 xl:col-start-3 xl:col-span-8 flex flex-col font-bold text-lg px-5 sm:px-0"
    >
    <h6 class="my-10 font-extrabold text-2xl">Лого ашиглах заавар</h6>
    <p class="text-base" style="color: #94A3B8;">
      М нэмэх ХХК-ны нэр болон лого нь компанийг төлөөлөх хамгийн үнэ цэнтэй зүйлсийн нэг юм. Тиймээс хэн нэгэн ямар нэгэн байдлаар Mbook логог зохисгүй ашиглах нь брэндийн өмч болон хууль эрх зүйн үүднээс тодорхой эрсдэлд оруулахыг анхаарна уу. Иймд та логог ашиглахдаа дараах заавартай заавал танилцахыг зөвлөж байна. Асууж тодруулах зүйл гарвал contents@mplus.mn хаягаар илгээгээрэй.
    </p>
      <div class="flex flex-row items-center justify-between  mt-16">
        <div class="flex flex-row space-x-2">
          <icon-face />
          <p><span class="hidden xs:inline">БРЭНДИЙН</span> ЛОГО</p>
        </div>

        <a
          :href="publicPath + 'mbook-assets.zip'"
          class="flex flex-row items-center shadow-md rounded-full py-2 pl-7 pr-4  text-sm font-light border brand-border-dark"
          download
        >
          <p class="flex-grow pr-3">Бүгдийг татах</p>
          <icon-download />
        </a>
      </div>

      <div class="mt-5 flex flex-wrap">
        <asset-box pngName="mbook.png" svgName="mplus.svg" />
        <asset-box
          pngName="mbookwhite.png"
          svgName="mpluswhite.svg"
          color="#0061FF"
        />
        <!-- <asset-box pngName="tseneg.png" svgName="tseneg.svg" />
        <asset-box pngName="huchdel.png" svgName="huchdel.svg" />
        <asset-box pngName="ochluur.png" svgName="ochluur.svg" /> -->
      </div>
      <div class="flex flex-row items-center space-x-2">
        <icon-droplet />
        <p>ӨНГӨ</p>
      </div>
      <div class="mt-5 flex flex-wrap">
        <color-box color="#0061FF" name="Үндсэн" />
        <color-box color="#0747CC" name="Бараан" />
        <color-box color="#192434" name="Хар" />
        <color-box color="#CED9EA" name="Идэвхгүй" />
      </div>
      <div class="h-20"></div>
    </div>
  </div>
</template>

<script>
import AssetBox from "./components/AssetBox.vue";
import ColorBox from "./components/ColorBox.vue";
import IconDroplet from "./assets/components/IconDroplet";
import IconFace from "./assets/components/IconFace";
import AppHeader from "./components/AppHeader";

import IconDownload from "./assets/components/IconDownload";

export default {
  name: "App",
  components: {
    AssetBox,
    ColorBox,
    IconDroplet,
    IconFace,
    AppHeader,
    IconDownload,
  },
  data() {
    return {
      publicPath: process.env.BASE_URL,
    };
  },
  meta: { title: "Mplus Assets" },
};
</script>
