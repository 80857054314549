<template>
   <svg class="h-6" viewBox="0 0 262 72" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M113.582 61.7302C109.703 61.7302 106.281 60.8935 103.314 59.2962C100.348 57.699 97.99 55.4932 96.2406 52.755C94.5673 49.9408 93.6545 46.7462 93.6545 43.1714C93.6545 39.5204 94.4912 36.402 96.2406 33.5877C97.99 30.7735 100.348 28.5677 103.314 27.0465C106.281 25.4492 109.779 24.6125 113.582 24.6125C117.385 24.6125 120.808 25.4492 123.775 27.0465C126.741 28.6438 129.099 30.7735 130.848 33.5117C132.598 36.2498 133.434 39.5204 133.434 43.1714C133.434 46.7462 132.598 49.9408 130.848 52.755C129.175 55.4932 126.817 57.699 123.775 59.2962C120.732 60.8935 117.385 61.7302 113.582 61.7302ZM113.582 53.1353C115.332 53.1353 116.929 52.755 118.298 51.9183C119.667 51.0817 120.808 49.9408 121.569 48.4956C122.405 46.9744 122.786 45.225 122.786 43.1714C122.786 41.0417 122.405 39.2923 121.569 37.8471C120.732 36.402 119.667 35.261 118.298 34.4244C116.929 33.5877 115.332 33.2074 113.582 33.2074C111.833 33.2074 110.236 33.5877 108.867 34.4244C107.498 35.261 106.357 36.402 105.52 37.8471C104.683 39.2923 104.303 41.1177 104.303 43.1714C104.303 45.225 104.683 46.9744 105.52 48.4956C106.357 49.9408 107.498 51.0817 108.867 51.9183C110.236 52.755 111.833 53.1353 113.582 53.1353Z" fill="#192434"/>
<path d="M98.6749 11.0739C101.793 11.0739 104.303 13.5895 104.303 16.7149V55.8214C104.303 58.9468 101.793 61.4625 98.6749 61.4625C95.5564 61.4625 93.0464 58.9468 93.0464 55.8214V16.6387C93.0464 13.5895 95.5564 11.0739 98.6749 11.0739Z" fill="#192434"/>
<path d="M157.242 61.7302C153.363 61.7302 149.94 60.8935 146.973 59.2962C144.007 57.699 141.649 55.4932 139.9 52.755C138.226 49.9408 137.314 46.7462 137.314 43.1714C137.314 39.5204 138.15 36.402 139.9 33.5877C141.649 30.7735 144.007 28.5677 146.973 27.0465C149.94 25.4492 153.439 24.6125 157.242 24.6125C161.045 24.6125 164.467 25.4492 167.434 27.0465C170.4 28.6438 172.758 30.7735 174.507 33.5117C176.181 36.2498 177.093 39.5204 177.093 43.1714C177.093 46.7462 176.257 49.9408 174.507 52.755C172.834 55.4932 170.476 57.699 167.434 59.2962C164.391 60.8935 161.045 61.7302 157.242 61.7302ZM157.242 53.1353C158.991 53.1353 160.588 52.755 161.957 51.9183C163.326 51.0817 164.467 49.9408 165.228 48.4956C166.065 46.9744 166.445 45.225 166.445 43.1714C166.445 41.0417 166.065 39.2923 165.228 37.8471C164.391 36.402 163.326 35.261 161.957 34.4244C160.588 33.5877 158.991 33.2074 157.242 33.2074C155.492 33.2074 153.895 33.5877 152.526 34.4244C151.157 35.261 150.016 36.402 149.179 37.8471C148.343 39.2923 147.962 41.1177 147.962 43.1714C147.962 45.225 148.343 46.9744 149.179 48.4956C150.016 49.9408 151.157 51.0817 152.526 51.9183C153.895 52.755 155.492 53.1353 157.242 53.1353Z" fill="#192434"/>
<path d="M201.661 61.7302C197.782 61.7302 194.359 60.8935 191.393 59.2962C188.427 57.699 186.069 55.4932 184.319 52.755C182.646 49.9408 181.733 46.7462 181.733 43.1714C181.733 39.5204 182.57 36.402 184.319 33.5877C186.069 30.7735 188.427 28.5677 191.393 27.0465C194.359 25.4492 197.858 24.6125 201.661 24.6125C205.464 24.6125 208.887 25.4492 211.853 27.0465C214.82 28.6438 217.177 30.7735 218.927 33.5117C220.6 36.2498 221.513 39.5204 221.513 43.1714C221.513 46.7462 220.676 49.9408 218.927 52.755C217.254 55.4932 214.896 57.699 211.853 59.2962C208.811 60.8935 205.464 61.7302 201.661 61.7302ZM201.661 53.1353C203.41 53.1353 205.008 52.755 206.377 51.9183C207.746 51.0817 208.887 49.9408 209.647 48.4956C210.484 46.9744 210.864 45.225 210.864 43.1714C210.864 41.0417 210.484 39.2923 209.647 37.8471C208.811 36.402 207.746 35.261 206.377 34.4244C205.008 33.5877 203.41 33.2074 201.661 33.2074C199.912 33.2074 198.314 33.5877 196.945 34.4244C195.576 35.261 194.435 36.402 193.599 37.8471C192.762 39.2923 192.382 41.1177 192.382 43.1714C192.382 45.225 192.762 46.9744 193.599 48.4956C194.435 49.9408 195.576 51.0817 196.945 51.9183C198.314 52.755 199.912 53.1353 201.661 53.1353Z" fill="#192434"/>
<path d="M232.618 10.6934C235.66 10.6934 238.094 13.0965 238.094 16.1002V55.6752C238.094 58.679 235.66 61.082 232.618 61.082C229.575 61.082 227.141 58.679 227.141 55.6752V16.1002C227.141 13.0965 229.575 10.6934 232.618 10.6934Z" fill="#192434"/>
<path d="M260.152 26.5141C262.282 28.6438 262.282 32.1426 260.152 34.2723L247.45 46.9745C245.32 49.1042 241.897 49.1042 239.768 46.9745C237.638 44.8448 237.638 41.346 239.768 39.2163L252.47 26.4381C254.523 24.3844 258.022 24.3844 260.152 26.5141Z" fill="#192434"/>
<path d="M260.151 59.829C258.022 61.9587 254.523 61.9587 252.393 59.829L239.615 47.1268C237.485 44.9971 237.485 41.5744 239.615 39.4447C241.745 37.315 245.244 37.315 247.373 39.4447L260.151 52.1468C262.281 54.2765 262.281 57.6993 260.151 59.829Z" fill="#192434"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M34.8766 65.8304C33.2485 64.9735 31.9632 63.6025 31.192 61.8887L18.5957 36.0107L22.5374 3.6203C22.9658 4.13443 23.3086 4.73425 23.5657 5.24839L40.3607 39.781L32.82 55.205C31.9632 56.9188 31.7061 58.8896 32.0488 60.7747C32.4773 62.7456 33.4199 64.4594 34.8766 65.8304Z" fill="#0747CC"/>
<path d="M8.99856 72C8.6558 72 8.22736 72 7.8846 71.9143C5.48531 71.6573 3.34309 70.3719 1.88638 68.4868C0.429673 66.6016 -0.255838 64.2023 0.0869178 61.8887L6.68496 8.16184C7.02771 5.84824 8.31304 3.79171 10.1982 2.42069C12.0834 1.04967 14.397 0.364158 16.7105 0.706914C19.0241 0.96398 21.0807 2.16362 22.5374 3.96309C23.9941 5.76255 24.6796 8.07615 24.5082 10.3898L17.9102 64.1166C17.6531 66.2589 16.6249 68.3154 14.9111 69.7721C13.283 71.2288 11.1408 72 8.99856 72Z" fill="#006AF5"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M70.6089 71.9143C70.2662 72 69.8377 72 69.495 72C67.2671 72 65.2105 71.2288 63.5824 69.7721C61.9543 68.3154 60.9261 66.3446 60.669 64.1167L57.4128 37.7245L71.8086 7.99048V8.07617L78.4066 61.8031C78.6637 64.2023 78.0638 66.5159 76.6071 68.4011C75.0647 70.3719 72.9225 71.6573 70.6089 71.9143Z" fill="#0747CC"/>
<path d="M39.2467 66.9444C37.7043 66.9444 36.2476 66.516 34.8766 65.8304C33.5055 65.0592 32.3916 64.031 31.6204 62.7457C30.8492 61.4603 30.335 60.0036 30.2494 58.4612C30.1637 56.9188 30.5064 55.4621 31.1062 54.0911L54.8421 5.33413C55.3562 4.22018 56.0417 3.19191 56.8986 2.42071C57.7555 1.56382 58.7837 0.963999 59.9834 0.535555C61.0973 0.107111 62.297 -0.0642666 63.4966 0.0214222C64.6963 0.107111 65.8959 0.364177 66.9242 0.878311C68.0381 1.39244 68.9807 2.16364 69.7519 3.10622C70.5231 4.0488 71.1229 5.07706 71.5514 6.27671C71.9798 7.47635 72.0655 8.67599 71.9798 9.87564C71.8941 11.0753 71.5514 12.2749 70.9516 13.3032L47.3014 61.8888C46.5302 63.4312 45.4163 64.7165 43.9596 65.5734C42.5885 66.4303 40.8748 66.9444 39.2467 66.9444Z" fill="#006AF5"/>
</svg>
</template>

<script>
export default {
  props: {
    active: {
      default: false
    }
  }
};
</script>

<style></style>
