<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :height="24 * resizeMultiply"
    viewBox="0 0 24 24"
    :width="24 * resizeMultiply"
    class="fill-current"
  >
    <path d="M0 0h24v24H0V0z" fill="none" />
    <path
      d="M9 16.17L5.53 12.7c-.39-.39-1.02-.39-1.41 0-.39.39-.39 1.02 0 1.41l4.18 4.18c.39.39 1.02.39 1.41 0L20.29 7.71c.39-.39.39-1.02 0-1.41-.39-.39-1.02-.39-1.41 0L9 16.17z"
    />
  </svg>
</template>

<script>
export default { props: { resizeMultiply: { default: 1 } } };
</script>

<style></style>
