<template>
  <div class="w-full xm:w-1/2 lg:w-1/3 py-5 xm:p-5">
    <div
      class="flex flex-col justify-between rounded-xl overflow-hidden shadow-lg"
    >
      <div
        class="p-5 w-full h-20 text-white font-base text-xs"
        :style="'background-color:' + color"
      >
        <p>{{ name }}</p>
      </div>
      <div
        class="p-5 w-full bg-white text-black flex flex-row items-center justify-between font-black text-xl"
      >
        <p>{{ color }}</p>
        <a
          @click="copyToClipboard(color)"
          class="brand-inactive-text rounded-full transition duration-200 brand-inactive-blue sm:bg-white hover:bg-gray-100 p-2 relative"
        >
          <icon-copy />
          <div
            class="top-0 -left-36 absolute transition duration-300 transform ease-in-out items-center flex text-xs rounded-md font-medium shadow-md  bg-white  py-2 pl-2 pr-4 space-x-3"
            :class="
              showNotif
                ? 'opacity-100 translate-x-0 '
                : 'opacity-0 translate-x-20 '
            "
          >
            <icon-correct v-if="showNotif" class="text-green-400" />
            <p v-if="showNotif" class="flex-grow text-black">Хуулагдлаа</p>
          </div>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import IconCopy from "../assets/components/IconCopy";
import IconCorrect from "../assets/components/IconCorrect";

export default {
  data() {
    return {
      showNotif: false,
    };
  },
  components: {
    IconCopy,
    IconCorrect,
  },
  props: ["color", "name"],
  methods: {
    copyToClipboard(color) {
      const el = document.createElement("textarea");
      el.value = color;
      document.body.appendChild(el);
      el.select();
      document.execCommand("copy");
      document.body.removeChild(el);
      this.showCopySuccess();
    },
    showCopySuccess() {
      this.showNotif = true;
      setTimeout(() => {
        this.showNotif = false;
      }, 1600);
    },
  },
};
</script>
<style>
a:hover {
  cursor: pointer;
}
</style>
