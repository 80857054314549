<template>
  <svg
    id="download_black_24dp"
    xmlns="http://www.w3.org/2000/svg"
    :width="24 * resizeMultiply"
    :height="24 * resizeMultiply"
    viewBox="0 0 24 24"
  >
    <path
      id="Path_15495"
      data-name="Path 15495"
      d="M0,0H24V24H0Z"
      fill="none"
    />
    <g
      id="Group_7839"
      data-name="Group 7839"
      transform="translate(154.781 -155.139)"
    >
      <path
        id="Path_15493"
        data-name="Path 15493"
        d="M-141.553,170.514c.015.013.032.022.047.033s.045.034.069.049a.873.873,0,0,0,.086.047c.024.012.047.024.072.034a1.077,1.077,0,0,0,.1.032c.022.006.044.013.066.017a.94.94,0,0,0,.179.018.935.935,0,0,0,.179-.018c.023,0,.044-.012.066-.017a1.031,1.031,0,0,0,.1-.032c.025-.01.048-.022.072-.034a.906.906,0,0,0,.086-.047c.024-.015.046-.032.069-.049s.032-.021.046-.033l4.589-3.933a.956.956,0,0,0,.1-1.349.957.957,0,0,0-1.349-.1l-3.01,2.58v-8.827a.957.957,0,0,0-.957-.957.957.957,0,0,0-.957.957v8.827l-3.01-2.58a.956.956,0,0,0-1.349.1.957.957,0,0,0,.1,1.349Z"
        transform="translate(-1.851)"
      />
      <path
        id="Path_15494"
        data-name="Path 15494"
        d="M-137.467,211.552h-10.627a.957.957,0,0,0-.957.957.956.956,0,0,0,.957.957h10.627a.956.956,0,0,0,.956-.957A.957.957,0,0,0-137.467,211.552Z"
        transform="translate(0 -38.54)"
      />
    </g>
  </svg>
</template>

<script>
export default { props: { resizeMultiply: { default: 1 } } };
</script>
<style></style>
