<template>
  <div class="w-full xm:w-1/2 lg:w-1/3 py-5 xm:p-5">
    <div
      class="rounded-xl h-60 flex items-center justify-center"
      :class="{ 'border brand-inactive-border': !color }"
      :style="'background-color: ' + color"
    >
      <img :src="publicPath + svgName" :alt="svgName" class="w-36 h-auto" />
    </div>
    <div
      class="flex flex-row space-x-3 items-center justify-end mt-3 text-sm font-light"
    >
      <a
        :href="publicPath + svgName"
        class="flex flex-row items-center border brand-border-dark rounded-full py-1 px-2"
        download
      >
        <p class="flex-grow pr-3">.svg</p>
        <icon-download />
      </a>
      <a
        :href="publicPath + pngName"
        class="flex flex-row items-center border brand-border-dark rounded-full py-1 px-2"
        download
      >
        <p class="flex-grow pr-3">.png</p>
        <icon-download />
      </a>
    </div>
  </div>
</template>

<script>
import IconDownload from "../assets/components/IconDownload";

export default {
  components: {
    IconDownload,
  },
  props: ["svgName", "pngName", "color"],

  data() {
    return {
      publicPath: process.env.BASE_URL,
    };
  },
};
</script>

<style></style>
