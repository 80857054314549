<template>
  <nav
    class="col-span-12 h-20 sm:h-16 content-center shadow-lg grid grid-cols-12 bg-white"
  >
    <div
      class="logo flex self-center col-span-2 sm:col-start-2 sm:col-span-1 xl:col-start-3 m-auto sm:m-0"
    >
      <div class="ml-4 xs:ml-5 sm:ml-0">
        <mplus />
      </div>
    </div>

    <div
      class="nav flex col-start-3 col-span-8 sm:col-start-3 sm:col-span-8 xl:col-start-4 xl:col-span-5 flex-row mx-auto xl:mx-0 space-x-10 sm:space-x-14 lg:space-x-9 items-center"
    >
      <a
       class="headerMenu text-base text-center items-center font-bold flex flex-row"
        style="font-size: 13px;"
        href="https://www.m-book.mn/audio"
      >
        <span class="hidden md:inline-block">Аудио ном</span>
      </a>
      <a
        class="headerMenu text-base text-center items-center font-bold flex flex-row"
        style="font-size: 13px; "
        href="https://www.m-book.mn/plus"
      >
        <span class="hidden md:inline-block">Товч ном</span>
      </a>
      <a
        class="headerMenu text-base text-center items-center font-bold flex flex-row"
        style="font-size: 13px;"
        href="https://www.m-book.mn/book"
      >
        <span class="hidden md:inline-block">Цахим ном</span>
      </a>
      <a
        class="headerMenu text-base text-center items-center font-bold flex flex-row"
        style="font-size: 13px;"
        href="https://www.m-book.mn/plus"
      >
        <span class="hidden md:inline-block">Түрээсийн номын сан</span>
      </a>
      <a
        class="headerMenu text-base text-center items-center font-bold flex flex-row"
        style="font-size: 13px;"
        href="https://www.m-book.mn/podcast"
      >
        <span class="hidden md:inline-block">Подкаст</span>
      </a>
      <a
        class="headerMenu text-base text-center items-center font-bold flex flex-row"
        style="font-size: 13px;"
        href="https://www.m-book.mn/plus"
      >
        <span class="hidden md:inline-block">Нэмэх</span>
      </a>
    </div>
  </nav>
</template>

<script>
import Mplus from "../assets/components/Mplus";
export default {
  components: {
    Mplus,
  },
};
</script>

<style></style>
